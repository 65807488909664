jQuery(document).ready(function ($) {
  // primary nav
  $('.nav-primary').accessibleNav({
    spans: 'hide',
    level2position: 'vertical-bottom',
    level3position: 'horizontal-right'
  });

  //Sticky Nav
  $(window).scroll(function () {
    if ($(this).scrollTop() > 56) {
      $('header').addClass("sticky");
    } else {
      $('header').removeClass("sticky");
    }

    $('.subpage header').addClass("sticky");
  });
  //Sticky Nav End

  //Nav Functionality - Start
  $('.nav-primary .nav-toggle').click(function () {
    $('.nav-primary .ul1').toggle();
    $('.nav-toggle .fa-bars').toggle();
    $('.nav-toggle .fa-times').toggle();
    $('header').toggleClass('fixed');
    $('body').toggleClass('fixed');
  });

  $('.nav-primary .nav-toggle').on('keypress', function (e) {
    if (e.which === 13) {
      $('.nav-primary .ul1').toggle();
      $('.nav-toggle .fa-bars').toggle();
      $('.nav-toggle .fa-times').toggle();
      $('header').toggleClass('fixed');
      $('body').toggleClass('fixed');
    }
  });
  //Nav Functionality - END

  //Search Functionality - Start
  $('.search-toggle').click(function () {
    $('.search-box').toggleClass('active');
    $('.search-toggle .fa-search').toggleClass('active');
    $('.search-toggle .fa-times').toggleClass('active');
  });

  $('.search-toggle').on('keypress', function (e) {
    if (e.which === 13) {
      $('.search-box').toggle();
      $('.search-toggle .fa-search').toggle();
      $('.search-toggle .fa-times').toggle();
    }
  });
  //Nav Functionality - END

  //Scroll to Top
  $(window).scroll(function () {
    if ($(this).scrollTop() > 250) {
      $(".scroll-to-top").css("opacity", "1");
    } else {
      $(".scroll-to-top").css("opacity", "0");
    }
  });
  $(".scroll-to-top").click(function () {
    $('html, body').animate({
      scrollTop: 0
    }, 1000);
    return false;
  });

  $('#main').responsivevideos();
  // Handle deep link to tabs by tab ID
  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
    return false;
  };

  var tabID = '#' + getUrlParameter('tab');
  var container = '#tabContainer' + getUrlParameter('tc');

  if (getUrlParameter('tab')) {
    if (getUrlParameter('tc')) {
      $(container).find('section').attr('aria-hidden', null);
      $(container).find('a').attr('aria-selected', null);
      switchTabs(tabID, container);
      $('html, body').animate({ scrollTop: $(container).offset().top - 350 });
    } else {
      $(tabContainer).find('section').attr('aria-hidden', null);
      $(tabContainer).find('a').attr('aria-selected', null);
      switchTabs(tabID, tabContainer);
      $('html, body').animate({ scrollTop: $(tabID).offset().top - 350 });
    }
  }

});

$(".button.hide").remove();
